import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-music-portfolio',
  templateUrl: './music-portfolio.component.html',
  styleUrls: ['./music-portfolio.component.scss']
})
export class MusicPortfolioComponent implements OnInit {

  showVideo = false

  listVideo = []

  constructor(
    public dialogRef: MatDialogRef<MusicPortfolioComponent>,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.listVideo = [
      {id:6, iframe: this.getVideoList('https://www.youtube.com/embed/Y8MHWgleUSY'), status:false, back:'assets/images/PorSiOlvidaRemix.jpeg', title:'Por Si Se Te Olvida Remix', date:'Noviembre 2022'},
      {id:5, iframe: this.getVideoList('https://www.youtube.com/embed/c0L02hGmxMQ'), status:false, back:'assets/images/PorSiOlvidaOfficial.jpeg', title:'Por Si Se Te Olvida', date:'Febrero 2022'},
      {id:4, iframe: this.getVideoList('https://www.youtube.com/embed/yzljnkZ53y4'), status:false, back:'assets/images/video-1.jpeg', title:'Vitamina P', date:'Octubre 2021'},
      {id:3, iframe: this.getVideoList('https://www.youtube.com/embed/1yXfcqu40do'), status:false, back:'assets/images/contact-mobile.jpeg', title:'Prohibida Unplugged', date:'Marzo 2021'},
      {id:2, iframe: this.getVideoList('https://www.youtube.com/embed/itSfZOBC65c'), status:false, back:'assets/images/video-3.jpeg', title:'Tu Última Canción', date:'Abril 2020'},
      {id:1, iframe: this.getVideoList('https://www.youtube.com/embed/nbETBETDMxI'), status:false, back:'assets/images/video-4.jpeg', title:'Prohibida', date:'Noviembre 2019'},
    ]
  }

  onClose(): void {
    this.dialogRef.close('close');
  }

  getVideoList(link) {
    var video, results;

    if (link === null) {
      return '';
    }
    results = link.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? link : results[1];

    //return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
    return this.sanitizer.bypassSecurityTrustResourceUrl(video)
  }

  selectVideo(event){
    this.listVideo.find(element => {
      if(element.id == event.id){
        element.status = true
      }
    })
  }
}
