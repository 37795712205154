import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AboutComponent } from '../about/about.component';
import { ContactComponent } from '../contact/contact.component';
import { GalleryComponent } from '../gallery/gallery.component';
import { MusicPortfolioComponent } from '../music-portfolio/music-portfolio.component';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  constructor(
    private dialog : MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialog(text){
    switch (text) {
      case 'contact':
        this.dialog.open(ContactComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          //data: 1,
          disableClose: true,
        });
        break;
      case 'music':
        this.dialog.open(MusicPortfolioComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          disableClose: true,
        });
        break;
      case 'about':
        this.dialog.open(AboutComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          disableClose: true,
        });
        break;
      case 'gallery':
        this.dialog.open(GalleryComponent, {
          maxWidth: '100vw',
          maxHeight: '100vh',
          height: '100%',
          width: '100%',
          disableClose: true,
        });
        break;
      default:
        break;
    }
  }
}
