<div class="lightbox-wrapper" id="contact">
    <div class="container">
      <button mat-icon-button class="modal-close-btn" (click)="onClose()">
        <i class="fa fa-times"></i>
      </button>

      <div class="row">
          <div class="col-12">
            <div class="lightbox-content" style="margin: 70px 0;">
              <div class="info-section single-section">
                <div class="row align-items-center">
                  <div class="col-12 col-md-12 col-lg-7 info-content">
                    <div fxHide fxShow.gt-sm class="section-heading page-heading" style="margin-bottom: 30px;">
                      <h4>{{'TITLE.CONTACT'| translate}}</h4>
                      <span>{{'MESSAGE.EVENT' | translate}} </span> <!--"Enterate de nuestros próximos eventos,"-->
                      <p class="text-contact">{{'MESSAGE.SUBSCRIBE_EVENT' | translate}} </p>
                    </div>
                    <div class="content-wrapper">
                      <div fxShow fxHide.gt-sm class="section-heading page-heading box-img" style="margin-bottom: 20px;">
                        <div class="text-title-img">
                          <h4>{{'TITLE.CONTACT'| translate}}</h4>
                        </div>
                        <img class="img-fluid img-mobile" src="assets/images/contact.jpeg" alt="Contact Mobile">
                      </div>
                      <form id="contact-form" [formGroup]="contactForm" (ngSubmit)="onContactFormSubmit(contactForm.value, formDirective)" #formDirective="ngForm">                             
                        <div class="row">
                          <div class="col-md-12 col-sm-12">
                              <mat-form-field appearance="outline" class="w-100">
                                  <mat-label>{{'FORM.NAME'| translate}}</mat-label>
                                  <input matInput placeholder="{{'FORM.NAME'| translate}}" formControlName="name" required>
                                  <mat-error *ngIf="contactForm.controls.name.errors?.required">{{'MESSAGE.REQUIRED_FIELD' | translate}}</mat-error>
                              </mat-form-field>
                          </div>
                          <div class="col-md-12 col-sm-12">
                              <mat-form-field appearance="outline" class="w-100">
                                  <mat-label>{{'FORM.EMAIL'| translate}}</mat-label>
                                  <input matInput placeholder="{{'FORM.EMAIL'| translate}}" formControlName="email" required>
                                  <mat-error *ngIf="contactForm.controls.email.errors?.required">{{'MESSAGE.REQUIRED_FIELD' | translate}}</mat-error>
                                  <mat-error *ngIf="contactForm.controls.email.hasError('invalidEmail')">{{'MESSAGE.INVALID_EMAIL' | translate}}</mat-error>
                              </mat-form-field>
                          </div>
                          <!--<div class="col-md-12 col-sm-12">
                              <mat-form-field appearance="outline" class="w-100">
                                  <mat-label>Teléfono</mat-label>
                                  <input matInput placeholder="Teléfono *" formControlName="phone" required>
                                  <mat-error *ngIf="contactForm.controls.phone.errors?.required">Teléfono requerido</mat-error>
                              </mat-form-field>
                          </div>
                          <div class="col-12">
                              <mat-form-field appearance="outline" class="w-100">
                                  <mat-label>Mensaje</mat-label>
                                  <textarea matInput placeholder="Mensaje" formControlName="message" required rows="6"></textarea>
                                  <mat-error *ngIf="contactForm.controls.message.errors?.required">Mensaje requerido</mat-error>
                              </mat-form-field>
                          </div>-->
                      </div>
                      <div class="w-100 py-2 text-center">
                          <button type="submit" class="btn btn-secondary-albatros" mat-raised-button>{{'BUTTON.SUBSCRIBE' | translate}}</button>
                      </div>
                      </form>
                    </div>
                  </div>
                  <div fxHide fxShow.gt-sm class="col-12 col-lg-5 info-img">
                    <div class="box-img">
                      <img class="img-fluid img-thumbnail" src="assets/images/contact.jpeg" alt="Contact">
                      <div class="text-img">
                        <ul class="list-unstyled icons">
                          <li><a href="https://music.apple.com/hn/artist/alexa-ferrari/396827473" target="_blank"><i class="fa fa-apple"></i></a></li>
                          <li><a href="https://open.spotify.com/artist/6DyPa98UKrIJWeqjOTZlng?si=qIStXO2xSWKl-i0t_zTKUQ" target="_blank"><i class="fa fa-spotify"></i></a></li>
                          <!--<li><a href="https://deezer.page.link/x4bw1ba2E91UFDz97" target="_blank"></a></li>-->
                          <li><a href="https://www.youtube.com/channel/UCS6iDgLE0nkNHkAFpbVx3HA" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                          <li><a href="https://www.instagram.com/alexaferrari/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                          <li><a href="https://www.facebook.com/LatinArtist" target="_blank"><i class="fa fa-facebook-square"></i></a></li>
                          <li><a href=" https://twitter.com/AlexaFerrari" target="_blank"><i class="fa fa-twitter"></i></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
</div>