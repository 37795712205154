<section class="home">
    <div class="container home-center h-100"></div>
    <div class="fixed-list">
        <div class="fixed-block block-left">
            <ul class="list-unstyled language-list">
                <li><a class="language" [class.active] = "language == 'en'" (click)="setLanguage('en')">ENG</a></li>
                <li><a class="language" [class.active] = "language == 'es'" (click)="setLanguage('es')">ESP</a></li>
            </ul>
        </div>
        <div class="fixed-block block-right">
            <ul class="list-unstyled icons">
                <li><a href="https://music.apple.com/hn/artist/alexa-ferrari/396827473" target="_blank"><i class="fa fa-apple"></i></a></li>
                <li><a href="https://open.spotify.com/artist/6DyPa98UKrIJWeqjOTZlng?si=qIStXO2xSWKl-i0t_zTKUQ" target="_blank"><i class="fa fa-spotify"></i></a></li>
                <!--<li><a href="https://deezer.page.link/x4bw1ba2E91UFDz97" target="_blank"></a></li>-->
                <li><a href="https://www.youtube.com/channel/UCS6iDgLE0nkNHkAFpbVx3HA" target="_blank"><i class="fa fa-youtube-play"></i></a></li>
                <li><a href="https://www.instagram.com/alexaferrari/" target="_blank"><i class="fa fa-instagram"></i></a></li>
                <li><a href="https://www.facebook.com/LatinArtist" target="_blank"><i class="fa fa-facebook-square"></i></a></li>
                <li><a href=" https://twitter.com/AlexaFerrari" target="_blank"><i class="fa fa-twitter"></i></a></li>
            </ul>
        </div>
    </div>
</section>