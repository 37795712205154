<div class="lightbox-wrapper" id="about">
    <div class="container">
      <button mat-icon-button class="modal-close-btn" (click)="onClose()">
        <i class="fa fa-times"></i>
      </button>

      <div class="row">
        <div class="col-12">
          <div class="lightbox-content"  style="margin: 10px 0;">
            <div class="section-heading page-heading my-1">
              <h4>Acerca de mí</h4>
              <!--<p>Descubra todas mis canciones digitalmente.</p>-->
            </div>

            <div class="mb-3">
              <a class="about-title" (click)="linkActive('About')" style="cursor: pointer;">ALEXA FERRARI </a> 
              <a class="about-title" (click)="linkActive('Time')" style="cursor: pointer;">| TIMELINE</a>
            </div>
          
            <div *ngIf="About" class="row">
              <div class="row overlay align-items-center mb-4">
                <div class="col-12 col-md-6 col-lg-6">
                  <p style="text-align: justify;">
                    Su álbum debut ‘Incontrolable’ fue reconocido como uno de los más importantes del pop-electrónico en Honduras, valiéndole el galardón <b>“Disco Del Año”</b> en los premios “Liquid
                    Music Video Awards”(2010). <br>
                    
                    De este trabajo también se desprendieron sonados éxitos en colaboración con figuras internacionales. Además de sus multitalentos le permiten
                    ser parte fundamental del proceso creativo de sus videos, los cuales han tenido
                    una alta rotación en canales internacionales como HTV y Ritmoson Latino. <br>
      
                    Gracias a su ímpetu creativo, su perseverancia y su indiscutible talento para estar al filo del
                    tiempo en tendencias musicales y de producción, ha desfilado en múltiples ocasiones por
                    alfombras rojas de Premios Latin Billboard y Premios Juventud.
                  </p>
                </div>
                <div class="col-12 col-md-6 col-lg-6">
                  <img class="img-fluid image-two" src="assets/images/gallery/gallery-3.jpeg" alt="Contact">
                </div>
              </div>
              
              <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-around center">
                <div fxFlex="100"  fxFlex.ge-md="20" fxFlex.gt-xs="30" fxFlex.gt-lg="20" class="text-center">
                  <img class="img-fluid img-audio" src="assets/images/PorSiOlvidaRemix.jpeg" alt="Contact">
                </div>
                <div fxFlex="100"  fxFlex.ge-md="80" fxFlex.gt-xs="70" fxFlex.gt-lg="80" class="p-2" style="z-index: 1;">
                  <p class="subtitle-box">
                    Por Si Se Te Olvida Remix
                    <a *ngIf="audioR" (click)="playAudio()"><i class="fa fa-play-circle"></i></a>
                    <a *ngIf="!audioR" (click)="stopAudio()"><i class="fa fa-stop-circle"></i></a>
                  </p>
                  <div class="content-box">
                    Una colaboración junto al reconocido artista urbano de Puerto Rico, Ken-Y,
                    con una lírica seductora, donde los dos intérpretes logran una sinergia de voces y
                    es los que fortalecen aún más la unión entre Honduras y Puerto Rico.
                  </div>
                </div>
              </div>
              <!--<div class="row align-items-center py-5">
                <div class="col-12 col-md-4 col-lg-4">
                  <div class="text-center">
                      <img class="img-fluid img-audio" src="assets/images/gallery/gallery-8.png" alt="Contact">
                  </div>
                </div>
                <div class="col-12 col-md-8 col-lg-8">
                  <p class="subtitle-box">
                    Vitamina P
                    <a *ngIf="audioR" (click)="playAudio()"><i class="fa fa-play-circle"></i></a>
                    <a *ngIf="!audioR" (click)="stopAudio()"><i class="fa fa-stop-circle"></i></a>
                  </p>
                  <div class="content-box">
                    Una colaboración junto a los reconocidos artistas urbanos de Puerto Rico, J-King y Maximan,
                    con una lírica seductora, donde los tres intérpretes logran una sinergia de voces y
                    es los que fortalecen aún más la unión entre Honduras y Puerto Rico.
                  </div>
                  <--<div> Escucha en <br>
                  <a href="https://music.apple.com/hn/artist/alexa-ferrari/396827473" target="_blank"><i class="fa fa-apple"></i></a>
                  <a href="https://open.spotify.com/artist/6DyPa98UKrIJWeqjOTZlng?si=qIStXO2xSWKl-i0t_zTKUQ" target="_blank"><i class="fa fa-spotify"></i></a>
                  </div>
                  --
                </div>
                <!-<audio controls>
                <source src="assets/audio/Vitamina P.wav" type="audio/wav">
                Your browser does not support the audio tag.
              </audio>->
              </div>-->
      
              <div class="row" style="margin-top: 30px;">
                <div class="col-12 my-3" style="text-align: justify;">
                  <p class="video-title">Colaboración</p>
                  Alexa Ferrari ha tenido la oportunidad de compartir escenarios con Marc Anthony, Ricky
                  Martin, RBD, Olga Tañón, Ha-Ash, Enrique Iglesias, Pitbull, Joey Montana, Prince Royce,
                  Juan Magan, J Balvin, CNCO, Mau y Ricky, Farruko, Manuel Turizo entre otros.
                  También ha trabajado con un selecto grupo de productores y compositores internacionales
                  entre quienes destacan DJ BLASS, Luny Tunes (Daddy Yankee, Don Omar, Wisin & Yandel),
                  Noriega, Mad Musik (Daddy yankee, Maluma), Eliot “El Mago deOZ” (JAlvarez, Olga Tañon,
                  Elvis Crespo) Wise (Fanny Lu, Chayanne, RBD) y más.
                </div> 
              </div>              
              
              <div class="row text-center">
                <div class="card single-about col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="assets/images/collaboration/dyland-lenny.jpeg" alt="Contact" class="img-fluid colla">
                  <div class="video-date">“Mírame”</div>
                </div>

                <div class="card single-about col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="assets/images/collaboration/jowelRandy.jpeg" alt="Contact" class="img-fluid colla">
                  <div class="video-date">“No Me Valoras”</div>
                </div>
                
                <div class="card single-about col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="assets/images/collaboration/zionLennox.jpeg" alt="Contact" class="img-fluid colla">
                  <div class="video-date">“Mi Cura”</div>
                </div>
                
                <div class="card single-about col-lg-3 col-md-3 col-sm-12 col-12">
                  <img src="assets/images/collaboration/DelaGhetto.jpeg" alt="Contact" class="img-fluid colla">
                  <div class="video-date">“Te Imagino Remix”</div>
                </div>
              </div>

              <!--<div class="page-content">
                <div class="card" style="background-image: url('assets/images/collaboration/dyland-lenny.jpeg');">
                  <div class='content'>
                    <h2 class='title'>Mírame</h2>
                  </div>
                </div>
                <div class="card" style="background-image: url('assets/images/collaboration/jowelRandy.jpeg');">
                  <div class="content">
                    <h2 class="title">No Me Valoras</h2>
                  </div>
                </div>
                <div class="card" style="background-image: url('assets/images/collaboration/zionLennox.jpeg');">
                  <div class='content'>
                    <h2 class='title'>Mi Cura</h2>
                  </div>
                </div>
                <div class="card" style="background-image: url('assets/images/collaboration/DelaGhetto.jpeg');">
                  <div class='content'>
                    <h2 class='title'>Te Imagino Remix</h2>
                  </div>
                </div>
              </div>-->

              <!--<div class="row">

                <div class="card">
                  <div class="card__image-holder">
                    <img class="card__image" src="https://source.unsplash.com/300x225/?wave" alt="wave" />
                  </div>
                  <div class="card-title">
                    <a class="toggle-info btn">
                      <span class="left"></span>
                      <span class="right"></span>
                    </a>
                    <h2>
                        Card title
                        <small>Image from unsplash.com</small>
                    </h2>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card__image-holder">
                    <img class="card__image" src="https://source.unsplash.com/300x225/?beach" alt="beach" />
                  </div>
                  <div class="card-title">
                    <a class="toggle-info btn">
                      <span class="left"></span>
                      <span class="right"></span>
                    </a>
                    <h2>
                        Card title
                        <small>Image from unsplash.com</small>
                    </h2>
                  </div>
                </div>
              
                <div class="card">
                  <div class="card__image-holder">
                    <img class="card__image" src="https://source.unsplash.com/300x225/?mountain" alt="mountain" />
                  </div>
                  <div class="card-title">
                    <a class="toggle-info btn">
                      <span class="left"></span>
                      <span class="right"></span>
                    </a>
                    <h2>
                        Card title
                        <small>Image from unsplash.com</small>
                    </h2>
                  </div>
                  
                </div>
              
                <div class="card">
                  <div class="card__image-holder">
                    <img class="card__image" src="https://source.unsplash.com/300x225/?field" alt="field" />
                  </div>
                  <div class="card-title">
                    <a class="toggle-info btn">
                      <span class="left"></span>
                      <span class="right"></span>
                    </a>
                    <h2>
                        Card title
                        <small>Image from unsplash.com</small>
                    </h2>
                  </div>
                  
                </div>
              
              </div>-->
              
              
              
              <div class="row" style="text-align: justify; margin-top: 30px;">
                En la actuación; trabajó en el piloto de una nueva serie para el canal juvenil Nickelodeon y
                ha realizado varias audiciones y lecturas de guiones para nuevos proyectos para la
                televisión hispana en Estados Unidos.
                Una de las prioridades de Alexa, siempre será el aporte comunitario, ya sea participando
                desde los cuatro años en diferentes Teletones de Honduras y de la región
                centroamericana, como participando en procesos benéficos de otras instituciones y
                propuestas comunitarias. <br>
                Ferrari trabaja en este momento en un gran proyecto que contará con la participación de
                productores y artistas de renombre de talla internacional para colocarse una vez mas
                como la artista hondureña más importante en el género urbano y poner una vez mas en el
                mapa a Honduras, permitiéndole así ingresar a los charts internacionales.
              </div>
      
              <div class="row align-items-center my-4">
                <div class="col-12 col-md-4 col-lg-4">
                  <img class="img-fluid img-about" src="assets/images/about-2.jpeg" alt="About">
                </div>
                <div class="col-12 col-md-8 col-lg-8">
                  <p style="text-align: justify;">
                    En Perú, la talentosa hondureña colaboró con el reconocido productor Peruano Juan
                    Carlos Fernández, con el tema musical “Siento”, canción que musicalizó la reconocida
                    telenovela peruana “Amor de Madre” y que significó un momento importante en la música
                    hondureña.
                    Dentro de sus múltiples facetas se destaca también como imagen de marcas locales y
                    multinacionales como Hugo, Ba, Pediasure, Kotex, Barena, Huggies, Volkswagen, Mabe, Mp Clean, Pantene, Black and Decker, Pepsi, Claro Honduras siendo una de las
                    figuras públicas y creadoras de contenidos mas importantes y cotizadas para el posicionamiento
                    de marcas comerciales en la región. <br>
                    Alexa se encuentra continuamente en procesos creativos, produciendo, creando e innovando; no solamente a nivel musical sino también con su
                    línea de ropa <b>“Incontrolable”</b>, que generará un impacto importante en la industria de la
                    creatividad alrededor del mundo.
                  </p>
                </div>
              </div>
            </div>
          
            <div *ngIf="Time" id="timeline">
              <div class="tl-item">  
                <div class="tl-bg" style="background-image: url(assets/images/2019.jpeg)"></div>
                <div class="tl-year">
                  <p class="f2 heading--sanSerif">2019</p>
                </div>
                <div class="tl-content">
                  <h1>Tu Última Canción</h1>
                  <!--<p>Lorem ipsum dolor sit amet.</p>-->
                </div>
              </div>
            
              <div class="tl-item">
                <div class="tl-bg" style="background-image: url(assets/images/2020.jpeg)"></div>
                <div class="tl-year">
                  <p class="f2 heading--sanSerif">2020</p>
                </div>
                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">Prohibida Unplugged</h1>
                  <!--<p>Suspendisse potenti.</p>-->
                </div>
              </div>
            
              <div class="tl-item">
                <div class="tl-bg" style="background-image: url(assets/images/contact-mobile.jpeg)"></div>
                <div class="tl-year">
                  <p class="f2 heading--sanSerif">2020</p>
                </div>
                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">Prohibida Unplugged</h1>
                  <!--<p>Mauris cursus magna at libero lobortis tempor. </p>-->
                </div>
              </div>
            
              <div class="tl-item">
                <div class="tl-bg" style="background-image: url(assets/images/2021.jpeg)"></div>
                <div class="tl-year">
                  <p class="f2 heading--sanSerif">2021</p>
                </div>
                <div class="tl-content">
                  <h1 class="f3 text--accent ttu">Vitamina P</h1>
                  <!--<p>Suspendisse ac mi at dolor sodales faucibus.</p>-->
                </div>
              </div>
            </div>
          
            <!--<div class="timeline">
              <div class="section-item">
                <div class="item" style="background-image: url(https://placeimg.com/800/800/nature)">
                  <div class="text">2019</div>
                </div>
              </div>
      
            </div>-->
          </div> 
        </div>
    </div>   
</div>