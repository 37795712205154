<nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <a class="navbar-brand"> <!--href="#"-->
        <img class="img-fluid" src="assets/images/logo_white.png" alt="Logo">
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
  
    <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item"><a class="nav-link" (click)="openDialog('about')">{{'HEADER.ABOUT'| translate}}</a></li>
            <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Media
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item"  (click)="openDialog('gallery')">{{'HEADER.GALLERY'| translate}}</a>
                    <a class="dropdown-item" (click)="openDialog('music')">{{'HEADER.MUSIC'| translate}}</a>
                </div>
            </li>
            <!--<li class="nav-item"><a class="nav-link" (click)="openDialog('gallery')">{{'HEADER.GALLERY'| translate}}</a></li>
            <li class="nav-item"><a class="nav-link" (click)="openDialog('music')">{{'HEADER.MUSIC'| translate}}</a></li>-->
            <li class="nav-item"><a class="nav-link" href="https://incontrolable.alexaferrari.com/">{{'HEADER.STORE'| translate}}</a></li>
            <li class="nav-item"><a class="nav-link" (click)="openDialog('contact')">{{'HEADER.CONTACT'| translate}}</a></li>
        </ul>
    </div>
</nav>