import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  About = true
  Time = false

  // MUSIC GENERAL
  audioR = true
  audio = new Audio();

  // COLLABORATION
  audioC = true 
  audioCollaboration = new Audio();
  
  constructor(
    public dialogRef: MatDialogRef<AboutComponent>,
  ) { }

  ngOnInit(): void {
  }

  onClose(): void {
    this.dialogRef.close('close');
  }

  linkActive(text){
    if(text == 'About'){
      this.About = true
      this.Time = false
    }else{
      this.About = false
      this.Time = true
    }
  }

  playAudio(){
    if(!this.audioC){
      this.stopAudioSongs()
    }

    this.audioR = false
    this.audio.src = "assets/audio/Por_Si_Se_Te_Olvida.wav";
    this.audio.load();
    this.audio.play();
  }

  stopAudio(){
    this.audioR = true
    this.audio.pause()
  }

  playAudioSongs(text){
    if(!this.audioR){
      this.stopAudio()
    }
    this.audioC = false 
    switch (text) {
      case 'Vitamina':
        this.audioCollaboration.src = "assets/audio/Vitamina P.wav";
        break;
      case 'Mírame':
        this.audioCollaboration.src = "assets/audio/1.mp3";
        break;
      case 'NoMeValoras':
        this.audioCollaboration.src = "assets/audio/2.mp3";
        break;
      case 'TeImaginoRemix':
        this.audioCollaboration.src = "assets/audio/3.mp3";
        break;
    }

    this.audioCollaboration.load();
    this.audioCollaboration.play();
  }

  stopAudioSongs(){
    this.audioC = true
    this.audioCollaboration.pause()
  }
}
