import { config } from './../../environments/config';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Injectable({ providedIn: 'root' })
export class ContactService {
  private URL = ''

  constructor(
    private http: HttpClient,
  ) {
    this.URL = config.domain

  }

  setContactUs(data) {
    let url_api = this.URL + 'api/em/contact_us/set'
    return this.http.post(url_api, data)
  }
}