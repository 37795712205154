<div class="lightbox-wrapper" id="music">
    <div class="container">
        <button mat-icon-button class="modal-close-btn" (click)="onClose()">
          <i class="fa fa-times"></i>
        </button>
        <div class="row">
            <div class="col-12">
                <div class="lightbox-content">
                    <div class="section-heading page-heading">
                        <h4>{{'TITLE.LISTEN'| translate}}</h4>
                        <p>{{'MESSAGE.MUSIC' | translate}} <br>
                            Suscríbete a mi canal en Youtube, activa la campana de notificaciones cuando lancemos un nuevo video.</p>
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="row">
            <div class="card single-gallery col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="video">
                    <iframe src="https://www.youtube.com/embed/yzljnkZ53y4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!showVideo" class="video-btn">
                        <a (click)="selectVideo()"><i class="fa fa-play"></i></a>
                    </div>
                </div>
                <h3 class="video-title">
                    Vitamina P
                </h3>
                <div class="video-date">Octubre 2021</div>
            </div>
            <div class="card single-gallery col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="video">
                    <iframe src="https://www.youtube.com/embed/1yXfcqu40do" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!showVideo" class="video-btn">
                        <a (click)="selectVideo()"><i class="fa fa-play"></i></a>
                    </div>
                </div>
                <h3 class="video-title">
                    Prohibida Unplugged
                </h3>
                <div class="video-date">Marzo 2021</div>
            </div>
            <div class="card single-gallery col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="video">
                    <iframe src="https://www.youtube.com/embed/itSfZOBC65c" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!showVideo" class="video-btn">
                        <a (click)="selectVideo()"><i class="fa fa-play"></i></a>
                    </div>
                </div>
                <h3 class="video-title">
                    Tu Última Canción
                </h3>
                <div class="video-date">Abril 2020</div>
            </div>
            <div class="card single-gallery col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="video">
                    <iframe src="https://www.youtube.com/embed/nbETBETDMxI" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!showVideo" class="video-btn">
                        <a (click)="selectVideo()"><i class="fa fa-play"></i></a>
                    </div>
                </div>
                <h3 class="video-title">
                    Prohibida
                </h3>
                <div class="video-date">Noviembre 2019</div>
            </div>
        </div>-->

        <div class="row">
            <div *ngFor="let item of listVideo" class="card single-gallery col-lg-6 col-md-6 col-sm-12 col-12">
                <div class="video">
                    <iframe [src]="item.iframe" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    <div *ngIf="!item.status" class="video-btn">
                        <a (click)="selectVideo(item)"><i class="fa fa-play"></i></a>
                    </div>
                    <div *ngIf="!item.status" class="front-video" style="background: url({{item.back}}) 50% 0% / cover no-repeat;"></div> <!--style="background-image: url(item.back)"-->
                </div>
                <h3 class="video-title">
                    {{item.title}}
                </h3>
                <div class="video-date">{{item.date}}</div>
            </div>
        </div>
    </div>
</div>
