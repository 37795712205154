import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  language = 'es'
  constructor(
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
  }

  setLanguage(language) {
    this.language = language
    this.translate.use(this.language)
  }
}
