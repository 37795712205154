import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContactService } from '../services/contact.service';
import { emailValidator } from '../utils/app-validator';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  
  contactForm: FormGroup;

  constructor(
    public formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ContactComponent>,
    private contactService: ContactService,
    public snackBar: MatSnackBar,
    //@Inject(MAT_DIALOG_DATA) public dialogData: any
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, Validators.compose([Validators.required, emailValidator])],
      //phone: [null, Validators.required],
      type: ['P', Validators.required],
      //message: [null, Validators.required]
    });
  }

  onClose(): void {
    this.dialogRef.close('close');
  }

  public onContactFormSubmit(values: Object, formDirective: FormGroupDirective): void {
    if (this.contactForm.valid) {
      console.log(values);
      this.contactService.setContactUs(this.contactForm.value).subscribe(res => {
        this.snackBar.open("Mensaje enviado", '×', { 
          panelClass: ['success-snack-bar'],
          verticalPosition: 'top',
          //horizontalPosition: 'right',
          duration: 3000 });
        formDirective.resetForm();
        this.contactForm.reset()
      })
    } else {
      this.snackBar.open("Por favor, complete el formulario", '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    }
  }
}
