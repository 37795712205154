<div class="lightbox-wrapper" id="gallery">
    <div class="container">
        <button mat-icon-button class="modal-close-btn" (click)="onClose()">
            <i class="fa fa-times"></i>
        </button>
        <div class="row">
            <div class="col-12">
                <div class="lightbox-content" style="margin-bottom: 30px;">
                    <div class="section-heading page-heading" style="margin-bottom: 0px;">
                        <h4>Galería</h4>
                        <!--<p>Descubra todas mis canciones digitalmente.</p>-->
                    </div>
                </div>
            </div>
        </div>
        <!--<div class="portfolio-section single-section">
            <div class="row">
            <div class="col-12">
                <ul class="list-inline filter-control" role="group">
                <li class="list-inline-item tab-active" data-filter="*">All</li>
                <li class="list-inline-item" data-filter=".brand">Brand</li>
                <li class="list-inline-item" data-filter=".design">Design</li>
                <li class="list-inline-item" data-filter=".photos">Photos</li>
                </ul>
            </div>
            </div>
            <div class="portfolio-grid row">
                <div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 seo" *ngFor="let item of galleryList" >
                    <div class="portfolio-item">
                        <img class="portfolio-img" [src]="item.img.length == 0 ? 'assets/images/other/not-found.png': item.img" [alt]=""
                                onerror="this.src='assets/images/other/not-found.png';">
                        <div class="portfolio-content">
                            <h4>{{item.title}}</h4>
                            <p>{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>-->

        <!--<div class="row">
            <div class="col-md-12 px-2">
                <div class="portfolioFilter text-center"> 
                    <div class="row">
                        <div class="col-xl-2 col-md-4 col-sm-6">
                            <a data-filter="*" class="current">Todos</a>
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                            <a data-filter=".seo">2019</a> 
                        </div>
                        <div class="col-xl-2 col-md-4 col-sm-6">
                            <a data-filter=".html">2020</a> 
                        </div>
                    </div>   
                </div>
            </div>
        </div>-->
        <div class="row">
            <!--<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12 seo" *ngFor="let item of galleryList" >
                <div class="portfolio-item">
                    <img class="portfolio-img" [src]="item.img.length == 0 ? 'assets/images/other/not-found.png': item.img" [alt]=""
                            onerror="this.src='assets/images/other/not-found.png';">
                    <div class="portfolio-content">
                        <h4>{{item.title}}</h4>
                        <p>{{item.description}}</p>
                    </div>
                </div>
            </div>-->
            <div class="gallery container-fluid">
                <div *ngFor="let item of images">
                    <img class="image-link" [src]="item.modal.img.length == 0 ? 'assets/images/other/not-found.png': item.modal.img" [alt]=""
                    onerror="this.src='assets/images/other/not-found.png';" (click)="openImageModal(item)">
                </div>
            </div>
            <ks-modal-gallery [id]="1" [modalImages]="images" [plainGalleryConfig]="customPlainGalleryRowDescConfig"></ks-modal-gallery>
        </div>

        <!--<div class="row">
            <div class="col-12">
              <div class="lightbox-content">
                <div class="info-section single-section">
                  <div class="row align-items-center">
                    <div class="col-12 col-md-12 col-lg-7 info-content">
                      <div class="content-wrapper">
                        <div class="section-heading page-heading">
                            <h4>Síguenos en Instagram </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
            </div>
            <div class="elfsight-app-e269e2e3-9f72-479c-99ff-f11159ee37d3"></div>
        </div>-->
    </div>
</div>