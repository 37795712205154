import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AdvancedLayout,
  Image,
  PlainGalleryConfig,
  PlainGalleryStrategy,
} from '@ks89/angular-modal-gallery';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  images: Image[] = [];
  
  customPlainGalleryRowDescConfig: PlainGalleryConfig = {
    strategy: PlainGalleryStrategy.CUSTOM,
    layout: new AdvancedLayout(-1, true)
  };

  galleryList = [
    {img:'assets/images/gallery/gallery-1.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-7.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-11.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-3.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-5.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-4.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-6.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-9.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-8.png', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-10.jpeg', title:'Single', description:'Tu Ultima Canción'},
    {img:'assets/images/gallery/gallery-12.jpeg', title:'Single', description:'Tu Ultima Canción'},
  ]

  constructor(
    public dialogRef: MatDialogRef<GalleryComponent>,
  ) { }

  ngOnInit(): void {
    this.onSetImages()
  }

  onClose(): void {
    this.dialogRef.close('close');
  }

  onSetImages() {
    this.images = []
    if(this.galleryList){
      for (var i = 0; i < this.galleryList.length; i++) {
        this.images.push(
          new Image(
            Number(i + 1),
            { 
              img: this.galleryList[i].img
              //description: this.galleryList[i].description,
            }
          ),
        )
      }
    }
    //this.galleryService.openGallery(1, 0);
  }

  openImageModal(image: Image){
    const index: number = this.getCurrentIndexCustomLayout(image, this.images);
    this.customPlainGalleryRowDescConfig = Object.assign({}, this.customPlainGalleryRowDescConfig, { layout: new AdvancedLayout(index, true) });
  }

  private getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  }

}
